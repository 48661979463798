import React from "react"
import Layout from "../components/index/layout"
import SEO from "../components/index/seo"
import { Row, Col, Card, Container } from "react-bootstrap"
import Header from "../components/index/Header"
import { Link } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import "../components/index/layout.css"

import image1 from "../images/landing-image-1.png"
import image2 from "../images/helmet.png"
import image3 from "../images/app-3.png"
import arrow from "../images/arrow.png"

const IndexPage = () => (
  <Layout>
    <Header id="homePageHeader" />
    <SEO
      title="Home"
      keywords={[
        `SimpleReport`,
        `SimpleReport.ca`,
        `reporting`,
        `report`,
        `safety`,
        `OHS`,
        `OSHA`,
        `OHS-reporting`,
        `OSHA-reporting`,
        `invoices`,
        `invoicing`,
        `ERP`,
        `Occupational Health and Safety`,
        `EHS`,
        `Environmental Health and Safety`,
      ]}
    />
    <link
      href="https://fonts.googleapis.com/css?family=Roboto&display=swap"
      rel="stylesheet"
    />
    <div id="homePage">
      <Row style={{ backgroundColor: "#F0F7FD" }}>
        <Col
          sm={12}
          lg={6}
          className="d-flex align-items-center bottomColumnHomePage"
        >
          <Container>
            <Col lg={8} className="mainPageText pl-3">
              <p>
                Simple Report is a digital HSE reporting and training paltform
                that will help your business Improve transparency, increase
                efficiency, boost productivity, and amplify credibility of data.
              </p>
              <br />
              <p>
                We provide services in training digital reporting that will
                streamline and automate day-to-day OHS reporting.
              </p>
              <br />
              <Link to="/services">
                <button className="homePageButton">
                  Learn more about our services <img src={arrow} />
                </button>
              </Link>
            </Col>
          </Container>
        </Col>
        <Col sm={12} lg={6} className="d-none d-lg-block image-block">
          <Card
            className="text-white blend"
            border="none"
            style={{ border: "none", minHeight: "40vh" }}
          >
            <Card.Img
              src={image1}
              style={{
                marginTop: "-90px",
                minWidth: "50vw",
                borderRadius: "0",
                backgroundColor: "black",
              }}
              alt="Card image"
            />
          </Card>
        </Col>
      </Row>
      <Row id="second-row-home-page">
        <Col sm={12} md={12} lg={5} className="image-overlay">
          <Container>
            <Col lg={10} className="mainPageText">
              <h4>Training</h4>
              <br />
              <p>
                Our HSE instructors extensive experience in Oil & Gas sector
                safety training in North America and Middle East.
              </p>
              <br />
              <p>
                Our training services include tailored instruction cirruculum to
                ensure that your company excels when it comes to safety.
              </p>
              <Link to="/services">
                <button className="homePageButton d-flex align-items-center justify-content-between">
                  Learn more about training
                  <img src={arrow} />
                </button>
              </Link>
            </Col>
          </Container>
        </Col>
        <Col sm={12} md={6} lg={7} className="d-none d-lg-block image-block ">
          <div
            className="text-white blend"
            border="none"
            style={{ border: "none", minHeight: "40vh" }}
          >
            <Card.Img
              className="ml-auto"
              id="cardImage2"
              src={image2}
              alt="Card image"
            />
          </div>
        </Col>
      </Row>
      <Row id="third-row-home-page">
        <Col sm={12} md={12} lg={5} className="d-flex bottomColumnHomePage">
          <Container className="d-flex align-items-center">
            <Col lg={10} className="mainPageText">
              <h4>Digital Services</h4>
              <br />
              <p>
                We offer services in Assurance Database Management (ADM) for HSE
                personnel to automate and monitor compliance throughout your
                organization.
              </p>
              <br />
              <p>
                Our training services include tailored instruction cirruculum to
                ensure that your company excels when it comes to safety.
              </p>
              <Link to="/services">
                <button className="homePageButton">
                  Learn more about ADM services
                  <img src={arrow} />
                </button>
              </Link>
            </Col>
          </Container>
        </Col>
        <Col
          sm={12}
          md={6}
          lg={7}
          className="d-none d-lg-block bottomColumnHomePage"
        >
          <Card
            className="text-white blend"
            border="none"
            style={{
              border: "none",
              backgroundColor: "transparent",
              display: "flex",
            }}
          >
            <Card.Img
              id="bottomRowImageHomePage"
              src={image3}
              alt="Card image"
            />
          </Card>
        </Col>
      </Row>
    </div>
  </Layout>
)

export default IndexPage
